import React from "react"
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { isValidPhoneNumber } from "react-phone-number-input"
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import { navigate } from 'gatsby'

import Layout from "../components/layout"
import ErrorSnackbar from "../components/error-snack"

import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

class IndexPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = { phone: '', open: false, errorMsg: this.props.intl.formatMessage({id: 'error.invalid'}) }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = event => {
    event.preventDefault()
  
    // Manage case of react-input-phone version where the + is not sent which generate an invalid phone number issue
    var intlNumber = this.state.phone.replace(/^([0-9])/, '+$1')
    if (!isValidPhoneNumber(intlNumber)) {
      this.setState({ open: true, errorMsg: this.props.intl.formatMessage({id: 'error.invalid'}) })
      return;
    }
    const data = {
      mobile: this.state.phone,
      smsgw: process.env.SMSGW_URL
    }
    fetch(`/apiproxy.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        console.log(response)
        if (response.data) {
          navigate('/success')
        } else {
          console.log(response.error);
          this.setState({ open: true, errorMsg: this.props.intl.formatMessage({id: 'error.occurred'}) })
        }
      })
      .catch(error => {
        console.error('Error: ', error)
        this.setState({ open: true, errorMsg: this.props.intl.formatMessage({id: 'error.occurred'}) })
      })
  }

  handleClose = () => this.setState({ open: false })

  render() {
    return (
      <Layout>
        <h1><FormattedMessage id="unsubscribe.title" /></h1>
        <p><FormattedMessage id="unsubscribe.content" /></p>
        <form onSubmit={ this.handleSubmit }>
          <ReactPhoneInput
            defaultCountry="fr"
            country="fr"
            name="icc"
            regions={['america', 'europe', 'asia', 'oceania', 'africa']}
            placeholder="e.g. 07 12 34 56 78"
            value={ this.state.phone }
            onChange={ newPhone => this.setState({ phone: newPhone }) }/>

          <Button
            style={{
              marginTop: `1rem`
            }}
            type="submit"
            variant="contained"
            color="secondary"><FormattedMessage id="unsubscribe.button" /></Button>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={ this.state.open }
            autoHideDuration={3000}
            onClose={ this.handleClose } >
            <ErrorSnackbar
              msg={ this.state.errorMsg }
              onClose={ this.handleClose }/>
          </Snackbar>
        </form>

        <GoogleReCaptchaProvider reCaptchaKey="6LcWwroUAAAAAJQLeis7KJ_Pj5iZ36NFkJfqd-oo">
          <GoogleReCaptcha onVerify={token => console.log(token)} />
        </GoogleReCaptchaProvider>
      </Layout>
    )
  }
}

export default injectIntl(IndexPage)
