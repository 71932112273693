import React from "react"
import PropTypes from "prop-types"

import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import ErrorIcon from "@material-ui/icons/Error"
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const ErrorSnackbar = ({ msg, onClose }) => {
  const classes = useStyle()

  return (
    <SnackbarContent
      className={ classes.error }
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <ErrorIcon className={classes.icon}/>
          {msg}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}

ErrorSnackbar.propTypes = {
  msg: PropTypes.string,
  onClose: PropTypes.func,
};

export default ErrorSnackbar